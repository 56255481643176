<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          v-show="isModuleActualEqualTwo"
          v-if="!isOnlyLead"
          variant="success"
          class="mr-1"
          @click="openModalSearch()"
        >
          <feather-icon
            icon="SearchIcon"
            size="15"
            class="mr-50 text-white"
          />Payments Search
        </b-button>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'crm-clients-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Clients</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'crm-clients-shared-list' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Shared</b-nav-item
      >
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";

export default {
  name: "Clients",
  components: {
    ModalSearchPayment,
  },
  data() {
    return {
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      skin: "appConfig/skin",
      G_STATE_LEADS: "CrmLeadStore/G_STATE_LEADS",
    }),

    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
    ...mapState({
      S_SELECTED_LEADS: (state) => state.CrmLeadStore.S_SELECTED_LEADS,
      S_FILTERS_LEADS: (state) => state.CrmLeadStore.S_FILTERS_LEADS,
    }),
  },
  async created() {
    await Promise.all([this.moduleId()]);
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
  },
};
</script>
